<template>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
    <van-cell-group title="扫描箱号" inset>
      <Picker
          name="pickOrder"
          label="捡配单"
          placeholder="请选择捡配单"
          displayField="code"
          valueField="id"
          :required=true
          v-model:value="this.$data['pickOrder']"
          :defConfig="{
                    portName:'fr.order.out.PickOrder.getPickOrders',
                    data: {'pickStatusCode': 'ORDER_PICK_STATUS_PICKED' },
            }"
          :canSearch=true
      ></Picker>
      <Picker
          name="customOrder"
          label="客户订单"
          placeholder="请选择客户订单"
          displayField="outStock.code"
          valueField="outStock.id"
          :required=true
          v-model:value="this.$data['customOrder']"
          :defConfig="{
                    portName:'fr.order.out.PickOrderOutStock.getPickOrderOutStocks',
                    data: {'pickOrder.id': this.$data['pickOrder'] },
            }"
          @onConfirm="onTabChange('unCheck')"
          :canSearch=true
      ></Picker>

      <van-cell>
        <van-field
                v-model="packageCode"
                name="packageCode"
                label="箱号"
                placeholder="请扫描物料包装"
                required
                :rules="[{ required: true, message: '请扫描物料包装' }]"
                @focus="this.focusModel= 'packageCode'"
        ></van-field>
      <van-button round block type="primary" @click="this.show= true,lastShow='packageCode'" style="height: 5.33333vw">
        扫描二维码
      </van-button>
      </van-cell>


    </van-cell-group>
  <van-cell-group  inset>
    <van-row  style="margin-top: 4.26667vw" >
      <van-col span="3">
        <van-button round style="width: 18.66667vw;" block type="primary" @click="resetCheck()">
          重置核验
        </van-button>
      </van-col>
      <van-col span="3"></van-col>
      <van-col span="15" style="margin-top: 4.26667vw">
          未检验数量:{{noCheckNum}} / 已检验数量:{{checkNum}}
      </van-col>
    </van-row>
  </van-cell-group>
    <van-cell-group title="物料列表" inset>
        <van-tabs v-model:active="active" @change="onTabChange" type="card" :lazy-render='false'>
            <van-tab title="未核验" name="unCheck">
                <div style="background-color: #fafafa">
                    <GridList name="materialGrid" title=""
                              port-name="fr.order.out.PickOrderDetail.getPickOrderDetails"
                              :request-data="{'checkStatus.code': 'OTHER_COMMON_YESNO_NO','outStockIds':this.customOrder.split('z54as8422341')}"
                              :immediate-check=false
                              :dataList="dataList" style="margin: 4.26667vw;" ref="grid">
                        <ListCard
                                v-for="item in dataList"
                                :key="item.id"
                                :id="item.id"
                        >
                            <div class="item">
                                <div class="fs12">
                                    <div class="tags fs12">{{'物料名称：' + item['material.name']}}</div>
                                    <div class="tags fs12">{{'批次号：' + item['batchNum']}}</div>
                                    <div class="tags fs12">{{'箱号：' + item['packageCode']}}</div>
                                    <div class="tags fs12">{{'需要数量：' + item['deliveryQty']}}</div>
                                </div>
                            </div>
                        </ListCard>
                    </GridList>
                </div>

            </van-tab>
            <van-tab title="已核验" name="checked">
                <div style="background-color: #fafafa">
                    <GridList name="materialGrid1" title=""
                              port-name="fr.order.out.PickOrderDetail.getPickOrderDetails"
                              :request-data="{'checkStatus.code': 'OTHER_COMMON_YESNO_YES','outStockIds':this.customOrder.split('z54as8422341')}"
                              :dataList="dataList" style="margin: 4.26667vw;" ref="checkedGrid">
                        <ListCard
                                v-for="item in dataList"
                                :key="item.id"
                                :id="item.id"
                        >
                            <div class="item">
                                <div class="fs12">
                                    <div class="tags fs12">{{'物料名称：' + item['material.name']}}</div>
                                    <div class="tags fs12">{{'批次号：' + item['batchNum']}}</div>
                                    <div class="tags fs12">{{'箱号：' + item['packageCode']}}</div>
                                    <div class="tags fs12">{{'需要数量：' + item['qty']}}</div>
                                </div>
                            </div>
                        </ListCard>
                    </GridList>
                </div>
            </van-tab>
        </van-tabs>
    </van-cell-group>
</template>

<script>
    import ListCard from "../../components/ListCard";
    import GridList from "../../components/GridList";
    import Picker from "../../components/Picker";
    import _ from "lodash";
    import QRReader from "@/components/QRReader.vue";
    import {Toast} from "vant";

    export default {
        name: "ScanCheck",
        components: {GridList, ListCard,Picker,QRReader},

        data() {
            return {
              show:false,
              lastShow:"",
              packageCode:"",
              customOrder:"",
              pickOrder:"",
              parentCode: '',
              childCode: '',
              focusModel: '',
              active: '',
              parentID: '',
              dataList: [],
              dataList1: [],
              noCheckNum:0,
              checkNum:0,
            }
        },
        created() {
            this.getScanCode();
        },
        methods: {

          onRead(text) {
            var me=this;
            if(this.lastShow ==='packageCode') {
              me.packageCode = text;
              var option1 = {
                portName: 'fr.order.out.PickOrderDetail.updateCheckStatus',
                data: { 'packageCode': me.packageCode},
                needLoading: true,
                successCallback: function (data) {
                  me.$dialog.alert({
                    title: '',
                    message: '核验成功',
                  }).then(() => {
                    me.packageCode = '';
                  });
                }
              };
              this.$sapi.callPort(option1);
            }
          },

            resetCheck(){
                var me = this;
                if(_.isEmpty(me.customOrder)){
                  this.$dialog.alert({
                    title: '',
                    message: '请先选择客户订单',
                  })
                }
                var option1 = {
                  portName: 'fr.order.out.PickOrderDetail.resetCheckStatus',
                  data: {'customOrder':this.customOrder},
                  successCallback: function (data) {
                    me.onTabChange('unCheck')
                  }
                };
                this.$dialog.confirm({
                  title: '',
                  message: '是否确定重置检验状态？',
                })
                    .then(() => {
                        this.$sapi.callPort(option1);
                    })
            },
            refreshData(){
              var me = this;
              var option1 = {
                portName: 'fr.order.out.PickOrderDetail.getPickOrderDetails',
                data: {'checkStatus.code': 'OTHER_COMMON_YESNO_NO','outStockIds':this.customOrder.split('z54as8422341')},
                successCallback: function (data) {
                  me.noCheckNum=data.totalcount
                }
              };
              this.$sapi.callPort(option1);
              var option2 = {
                portName: 'fr.order.out.PickOrderDetail.getPickOrderDetails',
                data: {'checkStatus.code': 'OTHER_COMMON_YESNO_YES','outStockIds':this.customOrder.split('z54as8422341')},
                successCallback: function (data) {
                  me.checkNum=data.totalcount
                }
              };
              this.$sapi.callPort(option2);
            },
            onTabChange(index) {
                if (index === "unCheck") {
                    this.$refs.grid.$props.requestData['outStockIds'] = this.customOrder.split('z54as8422341');
                    this.$refs.grid.onRefresh();
                    this.active = 'unCheck';
                    this.refreshData()
                }
                if (index === "checked") {
                    this.active = 'checked';
                    this.$refs.checkedGrid.onRefresh();
                    this.refreshData()
                }
            },
            getScanCode() {
                if (!window.Capacitor) {
                    return;
                }
                var me = this;
                Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                    var barcode = data.barcode;
                    switch (me.focusModel) {
                        // case 'parentCode' :
                        //     me.parentCode = barcode;
                        //     var option = {
                        //         portName: 'fr.warehouse.WareHouseMaterial.updateChildStatus',
                        //         data: {'parentCode': me.parentCode},
                        //         needLoading: false,
                        //         successCallback: function (data) {
                        //             me.parentID = data.data.parentID;
                        //             me.onTabChange(me.active);
                        //         }
                        //     };
                        //     this.$sapi.callPort(option);
                        //     break;
                        case 'packageCode' :
                            me.packageCode = barcode;
                            var option1 = {
                                portName: 'fr.order.out.PickOrderDetail.updateCheckStatus',
                                data: { 'packageCode': me.packageCode},
                                needLoading: true,
                                successCallback: function (data) {
                                    me.$dialog.alert({
                                        title: '',
                                        message: '核验成功',
                                    }).then(() => {
                                        me.packageCode = '';
                                    });
                                }
                            };
                            this.$sapi.callPort(option1);
                            break;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .item {
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: 0 0 2px #ddd;
        align-items: center;
        padding: 10px;
        margin: 2px;
    }

    .item + .item {
        margin-top: 10px;
    }

    .item > .content {
        flex: 1;
    }

    .item .time {
        color: #666;
    }
</style>